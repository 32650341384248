<script>
    export default {
        props: {
            platform: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
<template>
    <div class='row box error mt20'>
        <div
            class='col-md-12'
            v-if='platform'
        >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p v-html='$t("p-err-new-client-platform-transfer")'></p>
        </div>
        <div
            class='col-md-12'
            v-else
        >
            <p>
                {{ $t('p-err-unique-bankaccount-a') }}
            </p>
            <p>
                {{ $t('p-err-unique-bankaccount-b') }}
                <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a>
            </p>
        </div>
    </div>
</template>
