<script setup>
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import {ref} from 'vue';
import {i18n, t} from '@/i18n';
import Popup from '@/clientcomponents/Popup.vue';
import Translated from '@/components/Translated';

let open = ref(false);

function openPopup () {
    open.value = true;
}

function closePopup () {
    open.value = false;
}

function openProductPage () {
    const locale = i18n.global.locale.value === 'fr_FR' ? 'fr' : 'nl';
    const url = `https://www.codabox.com/${locale}/potential/`;
    window.open(url, '_blank');
}

</script>

<template>
    <Popup :show='open' :close='closePopup' class='text-left'>
        <h2 class='mt-0 mb-6 text-4xl'>
            {{ t('ttl-product-page-pop-up') }}
        </h2>
        <div>
            <Translated>
                <template #nl>
                    <p class='mb-3'>
                        Weet jij voor welke dossiers je bijkomende diensten kan activeren?
                        Vraag volledig vrijblijvend een analyse aan. Op basis van je actieve CODA-bestanden geven wij in een helder overzicht aan voor welke dossiers:
                    </p>
                    <ul class='mb-3'>
                        <li>
                            SODA mogelijk is per sociaal secretariaat,
                        </li>
                        <li>
                            VOILA mogelijk is per leverancier,
                        </li>
                        <li>
                            CARO mogelijk is per bank.
                        </li>
                    </ul>
                    <p class='mb-3'>
                        Duidelijk, gratis én vrijblijvend!
                    </p>
                </template>
                <template #en>
                    <p class='mb-3'>
                        Do you know which files you can activate additional services for?
                        Request an analysis without any obligation. Based on your active CODA files, we give you a clear overview indicating for which companies:
                    </p>
                    <ul class='mb-3'>
                        <li>
                            SODA is possible per social secretariat,
                        </li>
                        <li>
                            VOILA is possible per supplier,
                        </li>
                        <li>
                            CARO is possible per bank.
                        </li>
                    </ul>
                    <p class='mb-3'>
                        Clear, free and no strings attached!
                    </p>
                </template>
                <template #fr>
                    <p class='mb-3'>
                        Savez-vous quels sont les dossiers pour lesquels vous pouvez activer des services supplémentaires ?
                        Demandez une analyse sans engagement. Sur la base de vos fichiers CODA actifs, nous vous indiquons clairement pour quels dossiers :
                    </p>
                    <ul class='mb-3'>
                        <li>
                            SODA est possible par secrétariat social,
                        </li>
                        <li>
                            VOILA est possible par fournisseur,
                        </li>
                        <li>
                            CARO est possible par banque.
                        </li>
                    </ul>
                    <p class='mb-3'>
                        Tout à fait clair, gratuit et sans engagement !
                    </p>
                </template>
            </Translated>
            <div class='flex mt-6'>
                <FriendlyButton
                    square
                    extra-small
                    no-margin
                    label='lbl-product-page-pop-up'
                    @click='openProductPage'
                />
            </div>
        </div>
    </Popup>

    <FriendlyButton
        square
        extra-small
        no-margin
        label='lbl-product-page-pop-up'
        :action='openPopup'
    />
</template>
