<script>
    export default {
        name: 'CodaStateReason',
        props: {
            mandate: {
                type: Object,
                required: true,
            },
            wide: {
                type: Boolean,
                default: false,
            },
            multiline: {
                type: Boolean,
                default: false,
            },
        },
        data () {
            return {
                'stateReasonSizeLimit': 20,
            };
        },
        computed: {
            stateReason () {
                const signHereRejectionReason = (this.mandate.signHerePackage && this.mandate.signHerePackage.packageRejectionReason) || '';
                return this.$t({
                    'R-0': 'lgnd-reason-r-00',
                    'R-11': 'lgnd-reason-r-11',
                    'R-12': 'lgnd-reason-r-12',
                    'R-13': 'lgnd-reason-r-13',
                    'R-14': 'lgnd-reason-r-14',
                    'R-16': 'lgnd-reason-r-16',
                    'R-17': 'lgnd-reason-r-17',
                    'R-18': 'lgnd-reason-r-18',
                    'R-19': 'lgnd-reason-r-19',
                    'R-20': 'lgnd-reason-r-20',
                    'R-27': 'lgnd-reason-r-27',
                    'R-28': 'lgnd-reason-r-28',
                    'R-29': 'lgnd-reason-r-29',
                    'R-31': 'lgnd-reason-r-31',
                    'R-32': 'lgnd-reason-r-32',
                    'R-33': 'lgnd-reason-r-33',
                    'R-35': 'lgnd-reason-r-35',
                    'R-36': 'lgnd-reason-r-36',
                    'R-37': 'lgnd-reason-r-37',
                    'R-38': 'lgnd-reason-r-38',
                    'R-39': 'lgnd-reason-r-39',
                    'R-40': 'lgnd-reason-r-40',
                    'R-41': 'lgnd-reason-r-41',
                    'R-42': 'lgnd-reason-r-42',
                    'R-43': 'lgnd-reason-r-43',
                    'R-44': 'lgnd-reason-r-44',
                    'R-45': 'lgnd-reason-r-45',
                    'R-46': 'lgnd-reason-r-46',
                    'R-47': 'lgnd-reason-r-47',
                    'R-48': 'lgnd-reason-r-48',
                    'R-49': 'lgnd-reason-r-49',
                    'R-50': 'lgnd-reason-r-50',
                    'R-51': 'lgnd-reason-r-51',
                    'R-52': 'lgnd-reason-r-52',
                    'R-53': 'lgnd-reason-r-53',
                }[this.mandate.reasonCode] || 'lgnd-reason-r-00', { signHereRejectionReason });
            },
            stateLegend () {
                return this.$t({
                    'draft':            'lgnd-coda-pre-treatment',
                    'pre-treatment':    'lgnd-coda-pre-treatment',
                    'bank-procedure':   'lgnd-coda-bank-procedure',
                    'sent-hq':          'lgnd-coda-bank-procedure',
                    'prepared':         'lgnd-coda-prepared',
                    'sent-client':      'lgnd-coda-sent-client',
                    'problem':          'lgnd-coda-problem',
                    'available-online': 'lgnd-coda-available-online',
                    'active':           'lgnd-coda-active',
                    'archived':         'lgnd-coda-archived',
                    'signed':           'lgnd-coda-signed',
                }[this.mandate.state]);
            },
            displayStateLegend () {
                return ![
                    'archived',
                    'bank-procedure',
                    'sent-hq',
                    'available-online',
                    'draft',
                    'pre-treatment',
                ].includes(this.mandate.state);
            },
            notArchivedBankAccounts () {
                return this.mandate.bankAccounts.filter((ba) => ba.state !== 'archived');
            },
            isTwikey () {
                return this.mandate.bank.isTwikeySupported;
            },
            isSignhere () {
                return this.mandate.bank.isSignhereSupported;
            },
            isBankSupported () {
                return this.mandate.bank.isSupported;
            },
            reasonNote () {
                return this.mandate.reasonNote;
            },
        },
        methods: {
            notArchivedBankAccountsAllHaveFlowState (flowState) {
                return this.notArchivedBankAccounts.every((ba) => ba.flowState === flowState);
            },
            bankAccountsHaveState (state) {
                return this.mandate.bankAccounts.some((ba) => ba.state === state);
            },
        },
    };
</script>

<template>
    <span
        class='cb-ellipsis'
        :class='{"wide": wide, "multiline": multiline}'
    >
        <span class='cb-ellipsis-text'>
            <!-- no legend for archived, no html space for bank-procedure -->
            <span v-if='displayStateLegend'>
                {{ stateLegend }}&#160;
            </span>
            <!-- pre-treatment -->
            <template v-if='mandate.state === "pre-treatment" || mandate.state === "draft"'>
                <span v-if='isBankSupported'>{{ stateLegend }}&#160;</span>
                <span v-else>{{ $t('lgnd-coda-not-supported') }}</span>
            </template>
            <!-- available online -->
            <template v-if='mandate.state === "available-online"'>
                <span v-if='(isTwikey || isSignhere) && !notArchivedBankAccountsAllHaveFlowState("draft-active-bank-mandate")'>{{ $t('lgnd-coda-sign-via-twikey') }}</span>
                <span v-else-if='!(isTwikey || isSignhere) || notArchivedBankAccountsAllHaveFlowState("draft-active-bank-mandate")'>{{ stateLegend }}&#160;</span>
            </template>
            <!-- download or sign mandate via twikey section -->
            <template v-if='(mandate.state === "prepared" || mandate.state === "sent-client")'>
                <span v-if='(isTwikey || isSignhere) && !notArchivedBankAccountsAllHaveFlowState("draft-active-bank-mandate")'>{{ $t('lgnd-coda-sign-via-twikey') }}</span>
                <span v-else>{{ $t('lgnd-coda-sign-not-digital') }}</span>
            </template>
            <!-- bank-procedure (sent-hq) section -->
            <template v-if='mandate.state === "bank-procedure" || mandate.state === "sent-hq"'>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-if='bankAccountsHaveState("testing")' v-html='$t("lgnd-coda-bank-procedure-ba-testing")'></span>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-else-if='notArchivedBankAccountsAllHaveFlowState("pending-until-next-coda-file")' v-html='$t("lgnd-coda-bank-procedure-active-bank-mandate")'></span>
                <span v-else-if='(mandate.bankId === "KREDBEBB" || mandate.bankId === "CREGBEBB")'>
                    <span>
                        {{ $t('lgnd-coda-bank-procedure-kbc') }}&#160;{{ $t('coda-kbc-cbc-bank-procedure-label') }}&#160;
                        <a :href='$t("coda-kbc-cbc-bank-procedure-faq-url")' target='_blank'>{{ $t('coda-kbc-cbc-bank-procedure-faq-text') }}</a>
                    </span>
                </span>
                <span v-else-if='mandate.reasonCode === "R-10"'>
                    {{ $t('lgnd-coda-bank-procedure-twikey') }}
                </span>
                <span v-else-if='mandate.reasonCode === "R-34"'>
                    {{ $t('lgnd-coda-bank-procedure-digitally-signed') }}
                </span>
                <span v-else-if='mandate.reasonCode === "R-32"'>
                    {{ $t('lgnd-reason-r-32') }}
                </span>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <span v-else v-html='$t("lgnd-coda-bank-procedure")'></span>
            </template>
            <!-- bank-procedure section - END -->
            <span v-if='mandate.state === "problem" || mandate.state === "archived"'>
                {{ stateReason }}
                <span v-if='reasonNote'><br>{{ $t('lgnd-coda-reason-note') }} {{ reasonNote }}</span>
            </span>
        </span>
    </span>
</template>

<style scoped>
  .cb-ellipsis {
      display: inline-block;
      position: relative;
      font-size: 0;
  }

  .cb-ellipsis:hover .cb-ellipsis-tooltip {
      display: block;
      z-index: 1000;
  }

  .cb-ellipsis-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 200px;
      display: inline-block;
      font-size: 1.2rem;
  }

  .wide {
      width: 90%;
  }

  .wide .cb-ellipsis-text {
      width: 100%;
      max-width: 100%;
  }

  .multiline .cb-ellipsis-text {
      white-space: normal;
  }

  .wide .cb-ellipsis-text {
      width: 100%;
      max-width: 100%;
  }

  .cb-ellipsis-tooltip {
      position: absolute;
      background: white;
      display: none;
      z-index: 10;
      right: 0;
      width: 300px;
      min-height: 100px;
      padding: 10px;
      border-radius: 3px;
      border: solid 1px #d0d0d0;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.06);
      pointer-events: none;
      white-space: normal;
      font-size: 1.4rem;
  }
</style>
