<script>
import notify from '../notify.js';
import Popup from '@/clientcomponents/Popup.vue';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import { gql } from '@apollo/client/core';
import SearchInput from '@/components/SearchInput';
import List from '@/components/List';
import ListColumn from '@/components/ListColumn';
import ListRow from '@/components/ListRow';
import ListItem from '@/components/ListItem';
import Tooltip from '@/components/Tooltip';
import Translated from '@/components/Translated';

export default {
    name: 'VoilaBoostActivateAllButton',
    props: {
        currentEnvironment: { type: Object, required: true },
    },
    components: {
        FriendlyButton,
        Popup,
        SearchInput,
        List,
        ListColumn,
        ListRow,
        ListItem,
        Tooltip,
        Translated,
    },
    data () {
        return {
            opened: false,
            clients: [],
            loading: false,
            allChecked: true,
            textSearch: '',
        };
    },
    computed: {
        selectedList () {
            return this.clients.filter(c => c.matched && c.checked);
        },
        boostActivateButtonLabel () {
            if (this.selectedList.length === 1) {
                return this.$t('btn-boost-activate-all-confirm-sing');
            } else {
                return this.$t('btn-boost-activate-all-confirm', { count: this.selectedList.length || '' });
            }
        },
    },
    watch: {
        textSearch (v) {
            this.clients.map(c => {
                if (v.length > 0) {
                    c.matched = c.client.enterpriseName.toLowerCase().includes(v.toLowerCase()) ||
                                c.client.clientCode.toLowerCase().includes(v.toLowerCase());
                } else {
                    c.matched = true;
                }
            });
            this.verifyCheckAll();
        },
    },
    methods: {
        async getEligibleBoostClients () {
            this.loading = true;
            this.clients = (await this.$apollo.query({
                query: gql`query ClientsVoilaBoostEligible($input: ClientsVoilaBoostEligibleInput) {
                        clientsVoilaBoostEligible(input: $input) {
                            client {
                                id,
                                enterpriseName,
                                clientCode
                            },
                        }
                    }`,
                variables: {
                    input: {
                        fiduciaryId: this.currentEnvironment.id,
                    },
                },
            })).data.clientsVoilaBoostEligible.map(c => {
                return {
                    checked: true,
                    matched: true,
                    ...c,
                };
            });
            this.loading = false;
        },
        async doBoostActivate () {
            let clientIds = this.selectedList.map(c => c.client.id);
            await this.$apollo.mutate({
                mutation: gql`mutation ($input: BoostClientListInput) {
                    boostActivateClientList(input: $input) {
                        error {
                            code
                            message
                        }
                    }
                }`,
                variables: {
                    input: {
                        clientIds: clientIds,
                    },
                },
            });
            this.closePopup();
            notify.success(this.$t('suc-boost-activate-all'));
        },
        openPopup () {
            this.opened = true;
            this.clients = [];
            this.getEligibleBoostClients();
        },
        closePopup () {
            this.opened = false;
        },
        checkAll () {
            this.allChecked = !this.allChecked;
            for (const c of this.clients.filter(c => c.matched)) {
                c.checked = this.allChecked;
            }
        },
        checkItem (e) {
            this.verifyCheckAll();
        },
        verifyCheckAll () {
            // check the global checkbox if all checkboxes are checked, uncheck it if not
            this.allChecked = this.clients.filter(c => c.checked).length === this.clients.length;
        },
    },
};
</script>
<template>
    <div>
        <div>
            <FriendlyButton
                class='ml-auto'
                label='btn-boost-all'
                extra-small
                no-margin
                square
                :action='openPopup'
                id='voila-bulk-boost-button'
            />
        </div>
        <div>
            <Popup
                :show='opened'
                :close='closePopup'
                large
                fixed-height
                id='voila-bulk-boost-popup'
            >
                <template #header>
                    {{ $t('ttl-boost-activate-all-popup') }}
                    <Tooltip faded class='ml-2 boost-tooltip'>
                        <template #trigger>
                            <i class='fa fa-info-circle pb-5 text-2xl align-middle'></i>
                        </template>
                        <template #content>
                            <Translated>
                                <template #en>
                                    <p>This list shows companies with active CODA accounts. Based on your signed Boost contract with CodaBox, you may activate this client immediately on VOILA without requiring an additional confirmation from the company towards CodaBox.</p>
                                    <p><b>What follows after activation:</b></p>
                                    <ul class='pl-3'>
                                        <li>CodaBox sends your company a confirmation of activation on the service by email.</li>
                                        <li>After activation on VOILA, your company will receive his purchase invoices digitally via CodaBox.</li>
                                    </ul>
                                </template>
                                <template #nl>
                                    <p>Deze lijst toont dossiers met actieve CODA rekeningen. Op basis van jouw getekend Boost contract met CodaBox, mag je dit dossier onmiddellijk op VOILA activeren zonder dat een extra bevestiging van je dossier nodig is naar CodaBox toe</p>
                                    <p><b>Wat volgt er na activatie:</b></p>
                                    <ul class='pl-3'>
                                        <li>CodaBox stuurt je dossier per e-mail een bevestiging van activatie op de dienst.</li>
                                        <li>Na activatie op VOILA ontvangt je dossier zijn aankoopfacturen digitaal via CodaBox. Jouw kantoor ontvangt automatisch een kopie van deze aankoopfacturen.</li>
                                    </ul>
                                </template>
                                <template #fr>
                                    <p>Cette liste affiche les clients ayant des comptes CODA actifs. Sur base de votre contrat Boost signé avec CodaBox, vous pouvez immédiatement activer ce client sur VOILA, CodaBox n'attend pas d'autre confirmation de sa part.</p>
                                    <p><b>Ce qui suit après l'activation :</b></p>
                                    <ul class='pl-3'>
                                        <li>CodaBox envoie à votre dossier une confirmation d'activation au service via e-mail.</li>
                                        <li>Après l'activation sur VOILA, votre dossier reçoit ses factures d'achat sous forme numérique via CodaBox. Votre bureau reçoit automatiquement une copie de ces factures d'achat.</li>
                                    </ul>
                                </template>
                            </Translated>
                        </template>
                    </Tooltip>
                </template>

                <div class='flex flex-col' :class='{ "h-full": clients.length > 0}'>
                    <div class='flex mb-3 w-1/3'>
                        <SearchInput v-model='textSearch' id='voila-boost-bulk-search-fulltext' label='placeholder-boost-activate-all-search-input' />
                    </div>
                    <List :loading='loading' id='voila-boost-list' class='w-full flex-grow' scrollable>
                        <template #columns>
                            <ListColumn>
                                <input
                                    type='checkbox'
                                    id='scales'
                                    name='scales'
                                    :checked='allChecked'
                                    @change='checkAll'
                                >
                            </ListColumn>
                            <ListColumn>{{ $t('th-client-code') }}</ListColumn>
                            <ListColumn>{{ $t('th-client-name') }}</ListColumn>
                        </template>
                        <template #rows>
                            <ListRow
                                v-for='(c, i) in clients.filter(c => c.matched)'
                                :key='i'
                                :background='Number.isInteger(((i+1)/2))'
                            >
                                <ListItem class='w-3'>
                                    <input
                                        type='checkbox'
                                        id='scales'
                                        name='scales'
                                        v-model='c.checked'
                                        @change='checkItem'
                                    >
                                </ListItem>
                                <ListItem>
                                    {{ c.client.clientCode }}
                                </ListItem>
                                <ListItem>
                                    {{ c.client.enterpriseName }}
                                </ListItem>
                            </ListRow>
                        </template>
                    </List>

                    <div v-if='loading' class='text-xl flex justify-center'>
                        {{ $t('p-boost-activate-loading') }}
                    </div>

                    <div v-if='clients && clients.length <= 0 && !loading' class='text-grey-500 mt-3 text-xl'>
                        {{ $t('p-boost-activate-all-no-client') }}
                    </div>
                </div>

                <template #buttons>
                    <div class='flex w-full'>
                        <div class='text-xl mr-auto flex items-end'>
                            {{ selectedList.length }} / {{ clients.length }} {{ $t('lbl-boost-activate-all-count') }}
                        </div>
                        <FriendlyButton
                            label='btn-cancel'
                            class='cancel-voila-resend-invitation mr-3 ml-auto'
                            :action='closePopup'
                            symbol='times'
                            small
                            no-margin
                            secondary
                        />
                        <FriendlyButton
                            class='voila-order-manage-button'
                            :label='boostActivateButtonLabel'
                            small
                            no-margin
                            symbol='check'
                            id='voila-boost-all-do-activate'
                            :action='doBoostActivate'
                            :disabled='selectedList.length === 0'
                        />
                    </div>
                </template>
            </Popup>
        </div>
    </div>
</template>

<style scoped>
.info {
    max-width: 800px;
    margin-top: 30px;
}
.boost-tooltip {
    font-size: 14px;
    font-weight: normal;
}
</style>
