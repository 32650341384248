<script>
import clickOutside from '@/directives/click-outside';

export default {
    name: 'DropdownComponent',
    props: {
        tag: String,
        open: {
            type: Boolean,
            default: false,
        },
        hoverable: {
            type: Boolean,
            default: false,
        },
        large: {
            type: Boolean,
            default: false,
        },
        id: String,
    },
    directives: {
        clickOutside: clickOutside,
    },
    data () {
        return {
            opened: false,
        };
    },
    computed: {
        classList () {
            return `dropdown${this.opened ? ' open' : ''}`;
        },
    },
    mounted () {
        this.opened = this.$props.open;
    },
    methods: {
        toggleDropdown () {
            this.opened = !this.opened;
        },
        closeDropdown () {
            this.opened = false;
        },
        itemClick (item) {
            if (item.callback) {
                item.callback(item.slug ? item.slug : null);
            }
            this.toggleDropdown();
        },
    },
};
</script>

<template>
    <component
        :is='$props.tag'
        :class='classList'
        v-click-outside='closeDropdown'
        :id='id'
        @click='toggleDropdown'
    >
        <a
            class='dropdown-toggle'
            href='javascript:;'
        >
            <slot name='toggle'></slot>
        </a>
        <ul
            class='dropdown-menu pull-right'
            :class='{hoverable, large}'
        >
            <slot name='list'></slot>
        </ul>
    </component>
</template>

<style lang='postcss' scoped>
.dropdown-toggle {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: $color-grey-dark;
}

.large {
    width: 300px;
}

.dropdown-menu {
    padding: 10px;
}

.dropdown-menu.hoverable {
    padding-left: 0;
    padding-right: 0;
}

.hoverable li {
    padding: 5px 20px;
    cursor: pointer;
}

.hoverable li:hover {
    @apply bg-grey-100;
}
</style>
