<script>
    export default {
        name: 'CodaTermsEn',
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Service Description
        </h3>

        <p>CodaBox provides you directly with the electronic account statements in CODA format, for several Belgian financial institutions. This means your client no longer has to provide you with this account information, and your office has faster access to the financial data for accounting purposes.</p>

        <p>Via MyCodaBox you can order CODA for your clients. We will then take on the administrative process: drawing up the correct bank mandates, delivering these to your client for signature, follow-up with the banks, etc. You can follow up the status of the requests via MyCodaBox.</p>

        <p>CodaBox receives the first CODA file as soon as the bank has validated and activated the mandate, and a first transaction has taken place on the account. We then check the numbering, the version and balance, create a PDF file which collates all the information from the CODA file, and provide you with daily CODA files in a format and structure compatible with your accounting software.</p>

        <h3>Pricing</h3>

        <h4 class='cb-service-title'>
            Administrative costs
        </h4>

        <p>Mandates: 24 € per mandate</p>

        <p>Optional service – Delivery of CODA-files to your client via SFTP: 100 € per SFTP-server (<a href='https://faq.codabox.com/en/support/solutions/articles/75000087150-coda-also-delivered-to-your-client#link' target='_blank'>more info</a>)</p>

        <h4 class='cb-service-title'>
            Recurrent costs
        </h4>

        <table class='table'>
            <tbody>
                <tr>
                    <td>+ 500 current accounts</td>
                    <td>3,62 €/month/account</td>
                </tr>
                <tr>
                    <td>Between 241 and 500 current accounts</td>
                    <td>4,53 €/month/account</td>
                </tr>
                <tr>
                    <td>Between 121 and 240 current accounts</td>
                    <td>5,01 €/month/account</td>
                </tr>
                <tr>
                    <td>Between 61 and 120 current accounts</td>
                    <td>6,28 €/month/account</td>
                </tr>
                <tr>
                    <td>Between 3 and 60 current accounts</td>
                    <td>7,53 €/month/account</td>
                </tr>
                <tr>
                    <td>For 1 and 2 current accounts</td>
                    <td>9,37 €/month/account</td>
                </tr>
            </tbody>
        </table>

        <p>To determine the correct rate, the number of activated CARO-services and VOILA-services is also taken into account. More details can be found in the General Terms and Conditions, via the links below.</p>

        <p class='note'>
            <span class='fa fa-info-circle'></span>
            CodaBox cannot determine when the CODA, CARO or VOILA service is no longer needed for a particular client,
            credit card or bank account, for example, when there are no more transactions on an account, or the account is closed.
            The service remains active (and continues to be included in the calculation of recurrent costs) until you
            <a href='https://faq.codabox.com/en/support/solutions/articles/75000087558-how-to-deactivate-a-service-coda-soda-voila-caro-' target='_blank'>deactivate it yourself via MyCodaBox</a>.
        </p>

        <h3>General Terms and Conditions</h3>

        <p>
            Terms and conditions of the service:
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu FR-Annex CODA (B1-C1-D).pdf' target='_blank'>FR </a>
            <a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu NL-Annex CODA (B1-C1-D).pdf' target='_blank'>NL</a>
        </p>
    </div>
</template>

<style>
    h3.cb-service-main-title {
        margin-top: 10px;
    }

    h4.cb-service-title {
        text-decoration: underline;
    }

    .cb-service-info-highlight {
        font-style: italic;
        font-weight: bold;
    }
    p.note {
        font-style: italic;
    }
    p.note .fa-info-circle {
        font-size: 16px;
    }
</style>
