<script>
import NavBar from '@/components/NavBar.vue';
import store from '@/store';
import {Auth} from '@aws-amplify/auth';
import authUtils from '@/auth/utils';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import utils from '@/utils';
import { Form } from 'vee-validate';
import TOTP from '@/common/TOTP.vue';
import FormInput from '@/components/FormInput.vue';

export default {
    name: 'LoginView',
    components: {
        TOTP,
        NavBar,
        FriendlyButton,
        FormInput,
        // eslint-disable-next-line vue/no-reserved-component-names
        Form,
    },
    data () {
        return {
            isLoginOngoing: false,
            loginError: '',
            totpChallenge: null,
        };
    },
    watch: {
        '$store.state.maintenance': function () {
            store.dispatch('logout');
        },
    },
    methods: {
        async performLogin (values) {
            if (this.isLoginOngoing) return;
            this.loginError = '';
            this.isLoginOngoing = true;

            try {
                let cognitoUser = await Auth.signIn(values.email, values.password);
                if (cognitoUser && cognitoUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
                    this.totpChallenge = cognitoUser;
                } else {
                    authUtils.setAuthToken(cognitoUser.signInUserSession.accessToken.jwtToken);
                    authUtils.setIdToken(cognitoUser.signInUserSession.idToken.jwtToken);
                    // In case of 2FA, the TOTP.vue component will trigger it
                    await this.onSuccess();
                }
            } catch (e) {
                if (e.message && e.message === 'Incorrect username or password.') {
                    this.loginError = this.$t('err-invalid-username-password');
                }
                if (e.message && e.message === 'Password attempts exceeded') {
                    this.loginError = this.$t('err-login-attempts-exceeded');
                }
            }
            this.isLoginOngoing = false;
        },
        resetTotp () {
            this.totpChallenge = null;
        },
        async loadUser () {
            try {
                await this.$store.dispatch('loadUser');
                return true;
            } catch (e) {
                if (e.message === 'invalidUserConfiguration') {
                    this.loginError = this.$t('err-login-invalid-user-configuration');
                } else {
                    this.loginError = this.$t('err-login-cant-load-user');
                }
                if (this.loginError) {
                    await this.$store.dispatch('logout');
                }
                return false;
            }
        },
        async onSuccess () {
            if (await this.loadUser()) {
                this.$router.push({ name: 'index' });
            } else {
                this.resetTotp();
            }
        },
        validateEmail: utils.validateEmail,
    },
};

</script>

<template>
    <div class='login'>
        <NavBar no-nav transparent />
        <div>
            <div class='w-screen'>
                <div class='w-full mb-12 text-center px-6'>
                    <div class='w-full mb-12 flex justify-center'>
                        <img class='w-56' src='@/assets/mycodabox-logo.svg' alt='Mycodabox&apos;s logo'>
                    </div>
                </div>
                <div id='login-form' class='login__panel'>
                    <div class='bg-white shadow-xl drop-shadow-xl py-8 px-12 rounded-xl'>
                        <template v-if='totpChallenge'>
                            <TOTP
                                :totp-challenge='totpChallenge'
                                :on-success='onSuccess'
                                @go-back='resetTotp'
                            />
                        </template>
                        <template v-else>
                            <div v-if='loginError' class='rounded-md bg-red-300 text-white mb-4 px-6 py-3'>
                                {{ loginError }}
                            </div>
                            <Form tag='div' @submit='performLogin' v-slot='{ values }'>
                                <FormInput
                                    name='email'
                                    :label='$t("lbl-login")'
                                    rules='required'
                                    edit
                                    :value='$route.query.email && validateEmail($route.query.email) ? $route.query.email : ""'
                                />

                                <FormInput
                                    type='password'
                                    name='password'
                                    :label='$t("lbl-password")'
                                    rules='required'
                                    class='mt-3'
                                    edit
                                />

                                <div class='mt-6'>
                                    <FriendlyButton
                                        label='btn-login'
                                        no-margin
                                        fullwidth
                                        square
                                        small
                                        type='submit'
                                    />
                                </div>
                                <router-link :to='{ name: "forgot-password", query: values.email && validateEmail(values.email) ? { email: values.email } : {} }' class='inline-block mt-3'>
                                    {{ $t('a-forgot-password') }}
                                </router-link>
                            </Form>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.login {
    display: flex;
    background-size: cover;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 4rem);
}

.login__panel {
    @apply pb-64 my-auto mx-auto;
    max-width: 430px;
    width: 90%;
}

</style>
