<template>
    <div class='lg:flex lg:h-full'>
        <Sidenav collapsable>
            <SidenavGroup background>
                <SidenavItem
                    :to='{ name: "organization-administration-users" }'
                    :text='$t("nav-organization-administration-users")'
                    active-route='organization-administration-users'
                />
                <SidenavItem
                    :to='{ name: "organization-administration-connect" }'
                    :text='$t("nav-organization-administration-connect")'
                    active-route='organization-administration-connect'
                />
                <SidenavItem
                    :to='{ name: "organization-administration-information" }'
                    :text='$t("nav-organization-administration-information")'
                    active-route='organization-administration-information'
                />
            </SidenavGroup>
        </Sidenav>
        <div class='pl-6 lg:pl-0 pr-6 lg:h-full w-full lg:overflow-auto'>
            <Breadcrumb :more='{ organization: organization.enterpriseName }' class='mt-8' />
            <div class='min-h-screen'>
                <div class='flex flex-col justify-between min-h-screen'>
                    <router-view :organization='organization' @update:organization='updateOrganization' />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/Breadcrumb.vue';
    import Sidenav from '@/components/sidenav/Sidenav.vue';
    import SidenavItem from '@/components/sidenav/SidenavItem.vue';
    import SidenavGroup from '@/components/sidenav/SidenavGroup.vue';

    export default {
        name: 'OrgAdministration',
        props: {
            organization: {
                type: Object,
                required: true,
            },
        },
        emits: ['update:organization'],
        components: {
            Breadcrumb,
            Sidenav,
            SidenavItem,
            SidenavGroup,
        },
        methods: {
            updateOrganization (organization) {
                this.$emit('update:organization', organization);
            },
        },
    };
  </script>
