<script>
    export default {
        name: 'SearchboxComponent',
        props: {
            list: {
                type: Object,
                required: true,
            },
        },
    };
</script>

<template>
    <div class='cb-search-box' :class='{"not-empty":list.textsearch}'>
        <input
            class='form-control'
            type='text'
            v-model='list.textsearch'
            @submit.prevent.stop
            :placeholder='$t("lbl-search")'
        >
        <span class='cb-search-box-icon'>
            <i v-if='!list.reloading' class='fa fa-search'></i>
            <i v-else class='fa fa-spin fa-gear'></i>
        </span>
    </div>
</template>
