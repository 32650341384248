<script>
    import { gql } from '@apollo/client/core';
    import IBAN from 'iban';
    import bus from '@/bus.js';
    import { mapState } from 'vuex';
    import { Field } from 'vee-validate';
    import validate from '@/validate';

    export default {
        name: 'IbanInput',
        props: {
            fid: {type: String, default: null},
            modelValue: {type: String, default: ''},
            checkBank: {type: Boolean, default: true},
            label: {type: String, default: ''},
            noLabel: {type: Boolean, default: false},
            rules: {type: String, default: () => ''},
            name: String,
            formRef: Object,
            narrow: Boolean,
        },
        components: {
            Field,
        },
        // VUE-3-MIGRATION-DIRT
        compatConfig: { COMPONENT_V_MODEL: false },
        data () {
            return {
                PLACEHOLDER: 'BE__ ____ ____ ____',
                detectedBank: '',
                content: this.modelValue,
                loading: false,
            };
        },
        computed: {
            displayLabel () { return this.label !== null ? this.label : this.$t('lbl-iban'); },
            placeholder () { return this.getPlaceholder(this.modelValue); },
            required () {
                return this.rules.includes('required');
            },
            computedRules () {
                // if required is set put in front to be the first validator, before format
                // to display "required" instead of "invalid format" when input is empty
                const rulesNoRequired = this.rules.replace('required', '');

                let rulesWithIban = `iban${rulesNoRequired ? '|' + rulesNoRequired : ''}`;

                if (this.required) {
                    return `required|${rulesWithIban}`;
                }

                return rulesWithIban;
            },
            ...mapState(['allBanks']),
        },
        watch: {
            modelValue (newVal) {
                this.content = this.formatIban(newVal || '');
            },
        },
        mounted () {
            bus.on('validate', this.validate);
            this.content = this.formatIban(this.modelValue || '');
        },
        beforeUnmount () {
            bus.off('validate', this.validate);
        },
        emits: ['update:modelValue', 'blur'],
        methods: {
            sanitizeIBAN (iban) {
                if (iban) {
                    return iban.replace(/\s+/g, '');
                } else {
                    return iban;
                }
            },
            formatIban (input) {
                let res = input;
                res = validate.formatString(input, 'AADD DDDD DDDD DDDD');
                return res.toUpperCase();
            },
            getPlaceholder (input) {
                var res = '';
                for (var i = 0; i < this.PLACEHOLDER.length; i++) {
                    if (i < input.length) {
                        res += ' ';
                    } else {
                        res += this.PLACEHOLDER[i];
                    }
                }
                return res;
            },
            validate () {
                return this.validateIban(this.content);
            },
            async validateIbanQuery (iban) {
                const { data } = await this.$apollo.query({
                    query: gql`query bankAccountValidation($iban: String!) {
                        bankAccountValidation(iban:$iban) {
                            formatValid
                            bankSupportsCoda
                            blacklisted
                            bankId
                        }
                    }`,
                    variables: {
                        iban: iban,
                    },
                });
                return data.bankAccountValidation;
            },
            async validateIban (iban) {
                if (!this.formRef) return;

                this.loading = true;

                this.formRef.setErrors({
                    [this.name]: null,
                });
                this.detectedBank = '';
                if (IBAN.isValid(iban)) {
                    if (!this.checkBank) {
                        this.loading = false;
                        return true;
                    } else {
                        const BAValidation = await this.validateIbanQuery(iban);
                        this.loading = false;

                        // check if iban is blacklisted
                        if (BAValidation.blacklisted) {
                            this.formRef.setErrors({
                                [this.name]: this.$t('err-cannot-order'),
                            });
                            return false;
                        }

                        // check if bank account supports CODA
                        if (!BAValidation.bankSupportsCoda) {
                            this.formRef.setErrors({
                                [this.name]: this.$t('lgnd-coda-not-supported'),
                            });
                            return false;
                        }

                        // get the iban's corresponding bank
                        const bank = this.allBanks.find(b => b.id === BAValidation.bankId);

                        // if bank is know in our system, we show it to the user
                        if (bank) {
                            this.detectedBank = bank.name;
                            return true;
                        } else {
                            this.formRef.setErrors({
                                [this.name]: this.$t('val-unknown-bank'),
                            });
                            return false;
                        }
                    }
                } else {
                    this.loading = false;
                    return false;
                }
            },
            handleInput (e) {
                const res = this.formatIban(e.target.value);
                this.content = res;
                this.$refs.input.value = res;
            },
            exitField (e) {
                const res = this.formatIban(e.target.value);
                this.content = res;
                this.validateIban(res);
                this.$emit('blur');
            },
            selectAll (event) {
                setTimeout(function () {
                    event.target.select();
                }, 0);
            },
        },
    };
</script>
<template>
    <Field
        :name='name'
        :rules='computedRules'
        v-slot='{ field, errorMessage }'
        :value='content'
        ref='input'
        :validate-on-change='false'
        :validate-on-input='false'
        :validate-on-model-update='false'
    >
        <div>
            <label v-if='!noLabel' class='m-0 mb-2'>
                <span class='truncate'>
                    {{ displayLabel }}<span class='required' v-if='required'>*</span>
                </span>
            </label>
        </div>
        <div class='flex items-center w-full' :class='{"has-error": errorMessage, "narrow": narrow}'>
            <div class='h-12 w-full'>
                <input
                    v-bind='field'
                    class='h-full w-full text-gray-700 border-2 border-solid border-gray-200 rounded px-3 leading-7 bg-transparent outline-none focus:border-blue-300'
                    type='text'
                    autocomplete='off'
                    name='iban'
                    @input='handleInput'
                    @focus='selectAll'
                    @blur='exitField'
                    :id='fid'
                    :placeholder='placeholder'
                >
            </div>
            <template v-if='errorMessage'>
                <div class='cb-bai-status'>
                    <span class='cb-bai-status-icon'>
                        <i class='fa fa-times-circle'></i>
                    </span>
                    <span class='cb-bai-status-bank whitespace-nowrap'>{{ errorMessage }}</span>
                </div>
            </template>
            <template v-if='!errorMessage && detectedBank'>
                <div class='cb-bai-status'>
                    <span class='cb-bai-status-icon'>
                        <i class='fa fa-check-circle'></i>
                    </span>
                    <span class='cb-bai-status-bank whitespace-nowrap'>
                        {{ detectedBank }}
                    </span>
                </div>
            </template>
            <div v-if='loading'>
                <i class='fa fa-circle-o-notch fa-spin text-grey-300 ml-3'></i>
            </div>
        </div>
    </Field>
</template>

<style lang='scss' scoped>
/* ----------------- *\
   *    IBAN INPUT     *
  \* ----------------- */

.cb-bai-input {
  min-width: 185px;
  position: relative;
}

.narrow {
  width: 320px;
  flex-direction: column;
  align-items: flex-start;
}

.narrow .cb-bai-status {
  @apply mt-2;
  margin-left: 0;
}

.cb-bai-input-input,
.cb-bai-input-format {
  padding: 0;
  margin: 0;
  display: inline-block;
  background: transparent;
  border: none;
  position: absolute;
  white-space: pre;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  z-index: 10;
}
.cb-bai-input-input:focus {
  outline: none;
  border: none;
}
.cb-bai-input-format {
  color: #cbcbcb;
  z-index: 0;
}

.cb-bai-status {
  display: inline-flex;
  margin-left: 15px;
  padding: 7px 13px;
  background: rgba(103, 189, 37, 0.11);
  color: #4e8f1c;
  border-radius: 4px;
  vertical-align: middle;
}
.has-error .cb-bai-status {
  background: rgba(255, 0, 0, 0.1);
  color: #ff0000;
}

.cb-bai-status-icon {
  margin-right: 7px;
}

@media (max-width: 767px) {
  .cb-bai-status {
    display: block;
    margin-top: 10px;
    margin-right: 0px;
  }

  .cb-btn-tr {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    padding: 0px 11px;
    border-radius: 5px;
    text-align: center;
  }
}

.required {
    color: $primary-color;
}
</style>
