<script>

    import ListView from '@/components/ListView.js';
    import BankAccount from '@/components/BankAccount.vue';
    import CodaState from '@/components/CodaState.vue';
    import Twikey from '@/components/TwikeyNolink.vue';
    import ContentBox from '@/components/ContentBox.vue';
    import { dateFormat } from '@/filters';

    export default {
        name: 'CodaSearch',
        mixins: [ListView],
        props: {
            currentReseller: {
                type: Object,
            },
        },
        components: {
            BankAccount,
            CodaState,
            Twikey,
            ContentBox,
        },
        data () {
            const data = this.listViewData({
                apiModel: 'bff/api/search/reseller/coda-mandates',
                defaultSortKey: 'fiduciary__name',
                defaultFilters: {'client__state': 'active'},
            });

            const options = [{
                value: 'allfidus',
                label: 'opt-allfidus',
                filter: null,
            }];

            const fidus = this.currentReseller.fiduciaries;

            for (const fidu of fidus) {
                options.push({
                    value: fidu.id,
                    label: fidu.name,
                    filter: {'fiduciaryIds': [fidu.id]},
                });
            }

            this.configureSelectFilter(data, 'fiduciary', options, this.getUrlFidu());

            this.configureSelectFilter(data, 'state', [
                {
                    value: 'allstates',
                    label: 'opt-mandate-all',
                    filter: null,
                }, {
                    value: 'pre-treatment',
                    label: 'lbl-coda-pre-treatment',
                    filter: {'state': 'pre-treatment'},
                }, {
                    value: 'prepared',
                    label: 'lbl-coda-prepared',
                    filter: {'state': 'prepared'},
                }, {
                    value: 'sent-client',
                    label: 'lbl-coda-sent-client',
                    filter: {'state': 'sent-client'},
                }, {
                    value: 'available-online',
                    label: 'lbl-coda-available-online',
                    filter: {'state': 'available-online'},
                }, {
                    value: 'signed',
                    label: 'lbl-coda-signed',
                    filter: {'state': 'signed'},
                }, {
                    value: 'bank-procedure',
                    label: 'lbl-coda-bank-procedure',
                    filter: {'state': 'bank-procedure'},
                }, {
                    value: 'active',
                    label: 'lbl-coda-active',
                    filter: {'state': 'active'},
                }, {
                    value: 'archived',
                    label: 'lbl-coda-archived',
                    filter: {'state': 'archived'},
                }, {
                    value: 'problem',
                    label: 'lbl-coda-problem',
                    filter: {'state': 'problem'},
                },
            ]);

            return data;
        },
        watch: {
            '$route.query.fiduciaryId': function () {
                this.setSelectFilterValue('fiduciary', this.getUrlFidu());
            },
        },
        mounted () {
            this.watchFilters();
            this.search();
        },
        methods: {
            dateFormat: dateFormat,
            dynamicSearchParams () {
                return { 'resellerIds': [this.currentReseller.id] };
            },
            getUrlFidu () {
                return this.$route.query.fiduciaryId || 'allfidus';
            },
        },
    };
</script>

<template>
    <div>
        <div class='cb-fidu-title'>
            <div class='cb-fidu-actions'>
            </div>
            <h1 class='page-header'>
                {{ $t('h-coda-mandates') }}
            </h1>
        </div>

        <content-box>
            <form @submit.prevent.stop class='form-inline clearfix cb-fidu-search'>
                <searchbox :list='list' />

                <selector :filter='true' :model='list.filters.fiduciary' />
                <selector :filter='true' :model='list.filters.state' />

                <div class='cb-search-uppernav'>
                    <pagination
                        :list='list'
                        :pagination='pagination'
                        :ctrls='getControls()'
                        size='small'
                    />
                </div>
            </form>

            <div class='cb-bordered' :class='{"table-responsive": $store.state.gui === "mobile"}'>
                <table class='table cb-searchresults'>
                    <colgroup>
                        <collumn class='cb-col-md' :sorting='sorting' skey='fiduciary__name' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='client__client_code' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='client__name' />
                        <col class='cb-col-bg'>
                        <collumn class='cb-col-md' :sorting='sorting' skey='bank__name' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='state_modifed_at' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='state' />
                        <col class='cb-col-xs'>
                        <col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <span>{{ $t('th-fidu-name') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='fiduciary__name' />
                            </th>
                            <th>
                                <span>{{ $t('th-client-code') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='client__client_code' />
                            </th>
                            <th>
                                <span>{{ $t('th-client-name') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='client__name' />
                            </th>
                            <th>
                                <span>{{ $t('th-accounts') }}</span>
                            </th>
                            <th>
                                <span>{{ $t('th-bank') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='bank__name' />
                            </th>
                            <th>
                                <span>{{ $t('th-state-date') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='state_modified_at' />
                            </th>
                            <th>
                                <span>{{ $t('th-state') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='state' />
                            </th>
                            <th>
                                <span>{{ $t('th-e-signature') }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for='record in list.records'>
                            <td>{{ record.fiduciary.name }}</td>
                            <td>{{ record.client.client_code }}</td>
                            <td>{{ record.client.name }}</td>
                            <td>
                                <BankAccount v-for='ba in record.bank_accounts' :key='ba.iban' :bank-account='ba' />
                            </td>
                            <td>{{ record.bank.name }}</td>
                            <td>{{ dateFormat(record.state_modified_at) }}</td>
                            <td>
                                <CodaState :mandate='record' />
                            </td>
                            <td>
                                <Twikey :url='record.twikey_url' />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <listloader :list='list' />

            <div class='text-center' v-if='!list.loading'>
                <pagination
                    :list='list'
                    :pagination='pagination'
                    :ctrls='getControls()'
                    scroll='scrollTop'
                    no-margin
                />
            </div>
        </content-box>
    </div>
</template>
