<script>
    export default {
        name: 'PaginationComponent',
        props: {
            list: {
                type: Object,
                required: true,
            },
            pagination: {
                type: Object,
                required: true,
            },
            ctrls: {
                type: Object,
                required: true,
            },
            scroll: {
                type: String,
                validator: function (value) {
                    return ['scrollTop'].includes(value);
                },
            },
            size: {
                type: String,
                validator: function (value) {
                    return ['small'].includes(value);
                },
            },
            noMargin: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>
<template>
    <nav :class='{"cb-nav-bottom-spacing":(scroll === "scrollTop") && !noMargin}'>
        <ul class='pagination' :class='{"pagination-sm":(size === "small"), "mb-0": noMargin}'>
            <li class='cb-pagination-ctrl'>
                <span>
                    <template v-if='list.reloading'>
                        <b class='cb-results-count'>...</b>
                    </template>
                    <template v-else>
                        <b class='cb-results-count'>{{ pagination.recordCount }}</b>
                    </template>
                    <span> {{ $t('lbl-pagination-results') }} </span>
                    <span class='cb-pagesize'>
                        <span>{{ pagination.limit }}</span>
                        <i class='fa fa-caret-down'></i>
                        <div class='cb-pagesize-selector'>
                            <div @click.prevent='ctrls.setPageLimit(10)'>10</div>
                            <div @click.prevent='ctrls.setPageLimit(25)'>25</div>
                            <div @click.prevent='ctrls.setPageLimit(50)'>50</div>
                            <div @click.prevent='ctrls.setPageLimit(100)'>100</div>
                        </div>
                    </span>
                </span>
            </li>

            <li class='cb-prev-next cb-prev' :class='{"disabled": !pagination.prev}'>
                <a href='javascript:void(0)' @click.prevent='ctrls.prevPage(scroll)'>
                    <i class='fa fa-angle-double-left'></i>
                </a>
            </li>

            <li
                v-for='page in pagination.pages'
                :key='page.number'
                :class='{"active": page.active}'
                @click.prevent='ctrls.setPage(page.number, scroll)'
            >
                <a href='javascript:void(0)'>
                    {{ page.number }}
                </a>
            </li>

            <li :class='{"disabled": !pagination.next}'>
                <a href='javascript:void(0)' @click.prevent='ctrls.nextPage(scroll)'>
                    <i class='fa fa-angle-double-right'></i>
                </a>
            </li>
        </ul>
    </nav>
</template>
