<script>
    import CustomTitle from '@/components/Title.vue';

    export default {
        name: 'ClientTransfer',
        props: {
            accountant: {
                type: Object,
            },
            currentEnvironment: {
                type: Object,
            },
        },
        components: {
            CustomTitle,
        },
        computed: {
            hasRelatedFiduciaries () {
                return this.accountant.fiduciaries.length > 1;
            },
        },
    };
</script>

<template>
    <div>
        <header>
            <CustomTitle class='mt-6 mb-6'>
                {{ $t('h-clients-transfer') }}
            </CustomTitle>

            <ul class='nav nav-pills' v-if='hasRelatedFiduciaries'>
                <li
                    role='presentation'
                    :class='{ active: $route.name === "fidu-client-regular-transfer-list" }'
                >
                    <router-link :to='{ name: "fidu-client-regular-transfer-list" }'>
                        {{ $t('nav-fidu-client-regular-transfer-list') }}
                    </router-link>
                </li>
                <li
                    role='presentation'
                    :class='{ active: $route.name === "fidu-client-platform-transfer-list" }'
                >
                    <router-link :to='{ name: "fidu-client-platform-transfer-list" }'>
                        {{ $t('nav-fidu-client-platform-transfer-list') }}
                    </router-link>
                </li>
            </ul>
        </header>

        <router-view :current-environment='currentEnvironment' />
    </div>
</template>
