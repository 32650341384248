<script>
    import { gql } from '@apollo/client/core';
    import { simpleLocale } from '@/i18n.js';
    import PrismicPopup from '../components/PrismicPopup.vue';
    import Loader from '../loader.js';
    import Breadcrumb from '@/components/Breadcrumb.vue';
    import CustomTitle from '@/components/Title';
    import utils from '@/utils';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import Sidenav from '@/components/sidenav/Sidenav.vue';
    import SidenavItem from '@/components/sidenav/SidenavItem.vue';
    import SidenavGroup from '@/components/sidenav/SidenavGroup.vue';
    import SidenavCurrentEnv from '@/components/sidenav/SidenavCurrentEnv.vue';

    export default {
        name: 'FiduciaryView',
        components: {
            PrismicPopup,
            Breadcrumb,
            CustomTitle,
            FriendlyButton,
            Sidenav,
            SidenavItem,
            SidenavGroup,
            SidenavCurrentEnv,
        },
        data () {
            return {
                loaded: false,
                accountant: null,
            };
        },
        computed: {
            eboxUrl () {
                var locale = simpleLocale(this.$i18n.locale);
                return {
                    en: 'https://myebox.be/nl',
                    nl: 'https://myebox.be/nl',
                    fr: 'https://myebox.be/fr',
                }[locale];
            },
            featuresUrl () {
                var locale = simpleLocale(this.$i18n.locale);
                return {
                    en: 'https://faq.codabox.com/en/support/solutions/articles/75000031453-what-s-new-overview',
                    nl: 'https://faq.codabox.com/nl/support/solutions/articles/75000031453-wat-is-er-nieuw-overzicht',
                    fr: 'https://faq.codabox.com/fr/support/solutions/articles/75000031453-apercu-des-am%C3%A9liorations',
                }[locale];
            },
            currentEnvironment () {
                const currentEnvironment = this.accountant.fiduciaries.find((fidu) => {
                    return fidu.id === this.$route.params.environmentId;
                });
                return currentEnvironment || null;
            },
            hasCodaOnlyContract () {
                return this.accountant.hasCodaOnlyContract;
            },
        },
        watch: {
            '$route.params.organizationId': function (newValue, _) {
                // if organization changes, we want to update it
                // should later watch on environment when calls gonna be properly isolated
                this.loadData(newValue);
            },
            '$route.params.environmentId': function (newValue, _) {
                // save the last used environment in local storage
                localStorage['selectedEnvironmentId'] = newValue;
            },
        },
        async created () {
            try {
                await this.loadData(this.$route.params.organizationId);
            } catch {
                this.$router.push('/');
            }
        },
        methods: {
            async loadData (accountantId) {
                if (!utils.isUuid(accountantId)) return;
                this.loaded = false;
                Loader.start();
                try {
                    const {data} = await this.$apollo.query({
                        query: gql`query allFiduciaryData($accountantId: String!) {
                        banks {
                            id
                            name
                            slug
                            abbreviation
                            isSupported
                            isTwikeySupported
                            disableSendPdfMandate
                            isCaroSupported
                            isStopCodaSupported
                            regexSavings
                        }
                        accountant(accountantId:$accountantId) {
                            id
                            enterpriseName
                            enterpriseNumber
                            hasBelgianVatNumber
                            vatNumber
                            address
                            address2
                            zip
                            city
                            representativeName
                            representativeFunction
                            activeForDelivery
                            isDirectCustomer
                            hasCodaOnlyContract
                            fiduciaries {
                                id
                                accountantId
                                softwareId
                                softwareName
                                name
                                isExactOnline
                                language
                                contactEmail
                                contactName
                                delegatesMandateDelivery
                                state
                            }
                        }
                    }`,
                        variables: { accountantId },
                    });
                    this.$store.commit('setAllBanks', data.banks || []);
                    this.accountant = data.accountant;
                } catch {
                    // do nothing, a similar call is done in OrganizationView
                }

                Loader.stop();
                this.loaded = true;
            },
            // this method is setting the current fiduciary using a complete object
            // typically used to bubble up fiduciary updates from child components
            setCurrentFiduciary (fiduciary) {
                for (const fidu of this.accountant.fiduciaries) {
                    if (fidu.id === this.currentEnvironment.id) {
                        Object.assign(fidu, { ...fidu, ...fiduciary });
                    }
                }
            },
        },
    };
</script>

<template>
    <div class='h-full'>
        <template v-if='loaded && currentEnvironment'>
            <div class='h-full lg:flex'>
                <PrismicPopup space='fiduciary' />

                <Sidenav collapsable>
                    <!-- Current Environment -->
                    <SidenavGroup>
                        <SidenavCurrentEnv :name='currentEnvironment.name' />
                    </SidenavGroup>

                    <SidenavGroup background>
                        <SidenavItem
                            :to='{ name: "dashboard" }'
                            :text='$t("nav-dashboard")'
                            icon='home'
                            active-route='dashboard'
                        />
                    </SidenavGroup>

                    <!-- Group for Clients -->
                    <SidenavGroup :title='$t("nav-clients")' collapsable background>
                        <SidenavItem
                            :to='{ name: "fidu-client-search" }'
                            :text='$t("nav-fidu-client-search")'
                            :active-route='"client"'
                        />
                        <SidenavItem
                            :to='{ name: "fidu-client-regular-transfer-list" }'
                            :text='$t("nav-fidu-client-transfer-lists")'
                            :active-route='"client-transfer"'
                        />
                        <SidenavItem
                            :to='{ name: "fidu-client-new" }'
                            :text='$t("btn-new-client")'
                            icon='plus'
                            :active-route='"client-new"'
                        />
                    </SidenavGroup>

                    <!-- Group for Documents -->
                    <SidenavGroup :title='$t("nav-documents")' collapsable background>
                        <SidenavItem
                            :to='{ name: "fidu-coda-search" }'
                            :text='$t("nav-fidu-coda-search")'
                            :active-route='"coda"'
                        />
                        <template v-if='!hasCodaOnlyContract'>
                            <SidenavItem
                                :to='{ name: "fidu-soda-search" }'
                                :text='$t("nav-fidu-soda-search")'
                                :active-route='"soda"'
                            />
                            <SidenavItem
                                :to='{ name: "fidu-purchase-search" }'
                                :text='$t("nav-fidu-purchase-search")'
                                :active-route='"purchases"'
                            />
                            <SidenavItem
                                :to='{ name: "fidu-ccs-clients-list" }'
                                :text='$t("nav-fidu-ccs-clients-list")'
                                :active-route='"ccs-clients-list"'
                            />
                        </template>
                    </SidenavGroup>

                    <!-- Group for Settings -->
                    <SidenavGroup :title='$t("nav-environment-settings")' collapsable background>
                        <SidenavItem
                            :to='{ name: "fidu-services-root" }'
                            :text='$t("nav-fidu-services-root")'
                            :active-route='"services"'
                        />
                        <SidenavItem
                            :to='{ name: "fidu-info" }'
                            :text='$t("nav-fidu-info")'
                            :active-route='"info"'
                        />
                    </SidenavGroup>

                    <!-- Group for Links -->
                    <SidenavGroup :title='$t("nav-links")' collapsable background>
                        <SidenavItem
                            :external-link='eboxUrl'
                            :text='$t("nav-ebox")'
                        />
                        <SidenavItem
                            :external-link='featuresUrl'
                            :text='$t("nav-whats-new")'
                        />
                        <SidenavItem
                            external-link='https://status.codabox.com'
                            :text='$t("nav-status-page")'
                        />
                    </SidenavGroup>
                </Sidenav>

                <div v-if='loaded' class='pl-6 lg:pl-0 pr-6 lg:h-full w-full lg:overflow-auto'>
                    <Breadcrumb :more='{ organization: accountant.enterpriseName, environment: currentEnvironment.name }' class='mt-8' />
                    <router-view
                        :accountant='accountant'
                        :current-environment='currentEnvironment'
                        @fiduciary-updated='setCurrentFiduciary'
                    />
                </div>
            </div>
        </template>
        <template v-else-if='loaded'>
            <div class='container flex flex-col items-center justify-center h-full'>
                <CustomTitle class='mb-6 font-black text-center'>
                    {{ $t('ttl-err-no-access') }}
                </CustomTitle>
                <p class='text-center text-xl max-w-4xl mx-auto'>
                    {{ $t('p-err-no-access') }}
                </p>
                <FriendlyButton
                    label='btn-err-no-access'
                    symbol='home'
                    square
                    :action='() => $router.push("/")'
                />
            </div>
        </template>
    </div>
</template>
