<script>

    import ListView from '@/components/ListView.js';
    import Sodastate from '@/components/Sodastate.vue';
    import ContentBox from '@/components/ContentBox.vue';
    import { dateFormat } from '@/filters';

    export default {
        name: 'SodaSearch',
        mixins: [ListView],
        props: {
            currentReseller: {
                type: Object,
            },
        },
        components: {
            'sodastate':     Sodastate,
            ContentBox,
        },
        data () {
            const data = this.listViewData({
                apiModel: 'bff/api/search/reseller/social-mandates',
                defaultSortKey: 'fiduciary__name',
                defaultFilters: { 'client__state': 'active' },
            });

            const options = [{
                value: 'allfidus',
                label: 'opt-allfidus',
                filter: null,
            }];

            const fidus = this.currentReseller.fiduciaries;

            for (const fidu of fidus) {
                options.push({
                    value: fidu.id,
                    label: fidu.name,
                    filter: {'fiduciaryIds': [fidu.id]},
                });
            }

            this.configureSelectFilter(data, 'fiduciary', options, this.getUrlFidu());

            this.configureSelectFilter(data, 'state', [
                {
                    value: 'allstates',
                    label: 'opt-mandate-all',
                    filter: null,
                }, {
                    value: 'not-signed',
                    label: 'lbl-soda-not-signed',
                    filter: {'state': 'not-signed'},
                }, {
                    value: 'signed',
                    label: 'lbl-soda-signed',
                    filter: {'state': 'signed'},
                }, {
                    value: 'requested',
                    label: 'lbl-soda-requested',
                    filter: {'state': 'requested'},
                }, {
                    value: 'delayed',
                    label: 'lbl-soda-delayed',
                    filter: {'state': 'delayed'},
                }, {
                    value: 'confirmed',
                    label: 'lbl-soda-confirmed',
                    filter: {'state': 'confirmed'},
                }, {
                    value: 'active',
                    label: 'lbl-soda-active',
                    filter: {'state': 'active'},
                }, {
                    value: 'archived',
                    label: 'lbl-soda-archived',
                    filter: {'state': 'archived'},
                }, {
                    value: 'problem',
                    label: 'lbl-soda-problem',
                    filter: {'state': 'problem'},
                },
            ]);

            return data;
        },
        watch: {
            '$route.query.fiduciaryId': function () {
                this.setSelectFilterValue('fiduciary', this.getUrlFidu());
            },
        },
        mounted () {
            this.watchFilters();
            this.search();
        },
        methods: {
            dateFormat: dateFormat,
            dynamicSearchParams () {
                return { 'resellerIds': [this.currentReseller.id] };
            },
            getUrlFidu () {
                return this.$route.query.fiduciaryId || 'allfidus';
            },
        },
    };
</script>

<template>
    <div>
        <div class='cb-fidu-title'>
            <div class='cb-fidu-actions'></div>
            <h1 class='page-header'>
                {{ $t('h-soda-mandates') }}
            </h1>
        </div>

        <content-box>
            <form @submit.prevent.stop class='form-inline clearfix cb-fidu-search'>
                <searchbox :list='list' />

                <selector :filter='true' :model='list.filters.fiduciary' />
                <selector :filter='true' :model='list.filters.state' />

                <div class='cb-search-uppernav'>
                    <pagination
                        :list='list'
                        :pagination='pagination'
                        :ctrls='getControls()'
                        size='small'
                    />
                </div>
            </form>

            <div class='cb-bordered' :class='{"table-responsive": $store.state.gui === "mobile"}'>
                <table class='table cb-searchresults'>
                    <colgroup>
                        <collumn class='cb-col-md' :sorting='sorting' skey='fiduciary__name' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='client__client_code' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='client__name' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='social_welfare__name' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='state_modified_at' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='state' />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <span>{{ $t('th-fidu-name') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='fiduciary__name' />
                            </th>
                            <th>
                                <span>{{ $t('th-client-code') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='client__client_code' />
                            </th>
                            <th>
                                <span>{{ $t('th-client-name') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='client__name' />
                            </th>
                            <th>
                                <span>{{ $t('th-social-office') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='social_welfare__name' />
                            </th>
                            <th>
                                <span>{{ $t('th-state-date') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='state_modified_at' />
                            </th>
                            <th>
                                <span>{{ $t('th-state') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='state' />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for='record in list.records'>
                            <td>{{ record.fiduciary.name }}</td>
                            <td>{{ record.client.client_code }}</td>
                            <td>{{ record.client.name }}</td>
                            <td>{{ record.social_welfare.name }}</td>
                            <td>{{ dateFormat(record.state_modified_at) }}</td>
                            <td><sodastate :mandate='record' /></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <listloader :list='list' />

            <div class='text-center' v-if='!list.loading'>
                <pagination
                    :list='list'
                    :pagination='pagination'
                    :ctrls='getControls()'
                    scroll='scrollTop'
                    no-margin
                />
            </div>
        </content-box>
    </div>
</template>
