<template>
    <div id='main' class='page-host'>
        <router-view />
    </div>
    <UserSettingsPopup />
</template>

<script>
import store from './store';
import UserSettingsPopup from '@/user/UserSettingsPopup';

export default {
    name: 'MainApp',
    components: {
        UserSettingsPopup,
    },
    watch: {
        '$store.state.maintenance': function () {
            store.dispatch('logout');
        },
    },
    mounted () {
        this.onResize();
        window.addEventListener('resize', this.onResize);
    },
    beforeUnmount () {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        onResize () {
            let w = window.innerWidth;
            if (w <= 640) {
                store.commit('setGui', 'mobile');
            } else if (w < 1200) {
                store.commit('setGui', 'desktop');
            } else {
                store.commit('setGui', 'widescreen');
            }
        },
    },
};
</script>

<style lang='scss'>
    @import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
    @import './app.scss';
</style>

<style lang='postcss'>
/* shitty boostrap overrides */
body {
    @apply bg-grey-30;
}
.pagination>li>a,
.pagination>li>span {
    @apply text-blue-400;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    @apply bg-blue-400 border-blue-400;
}

input[type=checkbox], input[type=radio] {
    margin: 0;
}
</style>
