<script>
import notify from '../notify';
import { gql } from '@apollo/client/core';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import Popup from '@/clientcomponents/Popup';

export default {
    name: 'SendCodamandateByMailButton',
    props: {
        mandate: {
            type: Object,
        },
    },
    components: {
        Popup,
        FriendlyButton,
    },
    data: function () {
        return {
            sendByMailCurrentRecordId: '',
            showSendByMailPopup: false,
            isSendByMailConfirmed: false,
        };
    },
    computed: {
        enabled () {
            return this.mandate.state === 'sent-client';
        },
    },
    methods: {
        closeSendByMail () {
            this.showSendByMailPopup = false;
            this.isSendByMailConfirmed = false;
        },
        async confirmSendByMail () {
            if (!this.enabled) {
                return;
            }
            try {
                const {data} = await this.$apollo.mutate({
                    mutation: gql`mutation ($codaMandateId: String!) {
                        sendCodaMandateByMail(codaMandateId: $codaMandateId) {
                            errors {
                                code
                                detail
                                source {
                                    pointer
                                }
                            }
                        }
                    }`,
                    variables: {
                        codaMandateId: this.mandate.id,
                    },
                });
                if (!data.sendCodaMandateByMail.errors) {
                    notify.success(this.$t('send-by-mail-coda-success'));
                    this.mandate.state = 'prepared';
                } else {
                    notify.error(this.$t('err-unknown'));
                }
            } catch (err) {
                notify.error(this.$t('err-unknown'));
            }
            this.closeSendByMail();
        },
        sendByMail () {
            if (this.enabled) {
                this.showSendByMailPopup = true;
            }
        },
    },
};
</script>
<template>
    <div>
        <div
            class='btn btn-warning btn-xs'
            :class='{"btn-warning": enabled, "disabled": !enabled}'
            v-if='mandate.state === "sent-client"'
            @click.prevent='sendByMail'
        >
            {{ $t('btn-send-by-mail') }}
        </div>
        <Popup :show='showSendByMailPopup' :close='closeSendByMail' v-if='enabled'>
            <template #header>
                {{ $t('h-send-by-mail-confirm') }}
            </template>
            <div class='checkbox'>
                <label class='flex items-center'>
                    <input v-model='isSendByMailConfirmed' type='checkbox'>
                    {{ $t('label-send-by-mail-confirm') }}
                </label>
            </div>
            <template #buttons>
                <FriendlyButton
                    label='btn-cancel'
                    :action='closeSendByMail'
                    square
                    extra-small
                    no-margin
                    secondary
                    class='mr-2'
                />
                <FriendlyButton
                    label='btn-send-mandate'
                    :action='confirmSendByMail'
                    :disabled='!isSendByMailConfirmed'
                    square
                    extra-small
                    no-margin
                />
            </template>
        </Popup>
    </div>
</template>
