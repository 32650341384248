<script>
    import utils from '@/utils.js';
    import { mapState } from 'vuex';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import Popup from '@/clientcomponents/Popup';

    export default {
        name: 'PrismicPopup',
        props: {
            space: {
                type: String,
                required: true,
            },
        },
        components: {
            FriendlyButton,
            Popup,
        },
        data () {
            return {
                showPopup: true,
            };
        },
        computed: {
            noPrismic () {
                return this.$store.state.flags.noprismic;
            },
            popup () {
                if (!this.$store.state.popups.loaded) {
                    return null;
                } else {
                    let debug  = !!this.$store.state.flags.debug;
                    let popups = this.$store.state.popups.navigation;
                    let seen   = this.loadSeenPopups();
                    for (let popup of popups) {
                        if (!popup[this.space]) {
                            continue;
                        }
                        if (!debug && !popup.prod) {
                            continue;
                        }
                        if (!seen[popup.slug]) {
                            return popup;
                        }
                    }
                    return null;
                }
            },
            popupContent () {
                if (!this.popup) {
                    return {};
                }
                let documentId = this.popup[this.$i18n.locale];
                let doc = this.$store.state.popups.articles[documentId];
                if (!doc) {
                    console.error('Missing Prismic Document:', documentId);
                    return {};
                }

                return {
                    title: utils.prismicDomToText(doc.data['popup-title']),
                    body:  utils.prismicDomToHtml(this.$store, this.space, doc.data['popup-body']),
                };
            },
            ...mapState(['popups']),
        },
        mounted () {
            this.$store.dispatch('loadPrismicPopups');
        },
        methods: {
            close () {
                this.showPopup = false;
                if (this.popup) {
                    let email = this.$store.state.user.email;
                    let key   = 'prismic_popup_' + email;
                    let seen  = JSON.parse(localStorage[key] || '{}');
                    seen[this.popup.slug] = true;
                    localStorage[key] = JSON.stringify(seen);
                }
            },
            loadSeenPopups () {
                let email = this.$store.state.user.email;
                let key   = 'prismic_popup_' + email;
                let seen  = JSON.parse(localStorage[key] || '{}');
                return seen;
            },
        },
    };
</script>

<template>
    <Popup :show='showPopup' :close='close' v-if='popup && popups.visible && !noPrismic'>
        <template #header>
            {{ popupContent.title }}
        </template>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html='popupContent.body'></div>
        <template #buttons>
            <FriendlyButton :action='close' label='btn-ok' extra-small square />
        </template>
    </Popup>
</template>
