<script>
    export default {
        name: 'CodaTermsFr',
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Description du service
        </h3>

        <p>CodaBox vous fournit directement les extraits de compte électroniques au format CODA, pour plusieurs institutions financières belges. Ainsi, votre client ne doit plus vous fournir ces informations de compte, et votre bureau a un accès plus rapide aux données financières à des fins comptables.</p>

        <p>Via MyCodaBox vous pouvez commander le service CODA pour vos clients. Nous nous chargeons ensuite du reste du travail administratif : préparation des mandats bancaires corrects, livraison à votre client pour signature, suivi avec les banques, etc. Vous pouvez faire le suivi des mandats dans MyCodaBox.</p>

        <p>CodaBox reçoit le premier fichier CODA dès que la banque a validé et activé le mandat, et qu'une première opération a eu lieu sur le compte. Nous vérifions ensuite la numérotation, la version et le solde, créons un fichier PDF qui reprend les informations du CODA, et vous fournissons des fichiers CODA quotidiens dans un format et une structure compatible avec votre programme comptable.</p>

        <h3>Tarifs</h3>

        <h4 class='cb-service-title'>
            Frais administratifs
        </h4>

        <p>Mandats : 24 € par mandat</p>

        <p>Service optionnel - Livraison des fichiers CODA à votre client par SFTP : 100 € par serveur SFTP (<a href='https://faq.codabox.com/fr/support/solutions/articles/75000087150-coda-%C3%A9galement-livr%C3%A9-%C3%A0-votre-client#link' target='_blank'>plus d’infos</a>)</p>

        <h4 class='cb-service-title'>
            Frais récurrents
        </h4>

        <table class='table'>
            <tbody>
                <tr>
                    <td>+ 500 comptes à vue</td>
                    <td>3,62 €/mois/compte</td>
                </tr>
                <tr>
                    <td>Entre 241 et 500 comptes à vue</td>
                    <td>4,53 €/mois/compte</td>
                </tr>
                <tr>
                    <td>Entre 121 et 240 comptes à vue</td>
                    <td>5,01 €/mois/compte</td>
                </tr>
                <tr>
                    <td>Entre 61 et 120 comptes à vue</td>
                    <td>6,28 €/mois/compte</td>
                </tr>
                <tr>
                    <td>Entre 3 et 60 comptes à vue</td>
                    <td>7,53 €/mois/compte</td>
                </tr>
                <tr>
                    <td>Entre 1 et 2 comptes à vue</td>
                    <td>9,37 €/mois/compte</td>
                </tr>
            </tbody>
        </table>

        <p>Pour déterminer le tarif correct, le nombre de services activés pour CARO et VOILA est également pris en compte. Vous trouverez plus de détails dans les Conditions Générales, via le lien ci-dessous.</p>

        <p class='note'>
            <span class='fa fa-info-circle'></span>
            CodaBox ne peut pas déterminer quand le service CODA, CARO ou VOILA n'est plus nécessaire pour un certain client, une carte de crédit ou un compte bancaire, par exemple lorsqu'il n'y a plus de transactions sur un compte ou le compte est clôturé. Le service reste actif (et fait partie du calcul des frais récurrents) jusqu'à ce que vous le
            <a href='https://faq.codabox.com/fr/support/solutions/articles/75000087558-comment-d%C3%A9sactiver-un-service-coda-soda-voila-caro-' target='_blank'>désactiviez vous-même via MyCodaBox</a>.
        </p>

        <h3>Conditions Générales</h3>

        <p><a href='/static/pdfs/MyCodaBox.com 202401 CB-Fidu FR-Annex CODA (B1-C1-D).pdf' target='_blank'>Conditions générales du service</a></p>
    </div>
</template>

<style>
    h3.cb-service-main-title {
        margin-top: 10px;
    }

    h4.cb-service-title {
        text-decoration: underline;
    }

    .cb-service-info-highlight {
        font-style: italic;
        font-weight: bold;
    }
    p.note {
        font-style: italic;
    }
    p.note .fa-info-circle {
        font-size: 16px;
    }
</style>
