<script>
import _ from 'lodash';
import { gql } from '@apollo/client/core';
import CustomTitle from '@/components/Title';
import SearchInput from '@/components/SearchInput';
import Dropdown from '@/components/DropdownV2';
import DropdownItem from '@/components/DropdownItem';
import List from '@/components/List';
import ListColumn from '@/components/ListColumn';
import ListRow from '@/components/ListRow';
import ListItem from '@/components/ListItem';
import VoilaStatePill from '@/fiduciary/VoilaStatePill';
import { VoilaMandateStates } from '@/constants/voila2';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import Tooltip from '@/components/Tooltip';
import ContentBox from '@/components/ContentBox';
import ResendVoilaInviteButton from './components/ResendVoilaInviteButton';
import notify from '@/notify.js';
import Popup from '@/clientcomponents/Popup.vue';
import VoilaBoostActivateAllButton from '@/components/VoilaBoostActivateAllButton';
import ProductPageButton from '@/components/ProductPageButton.vue';
import { t } from '@/i18n';

export default {
    name: 'Voila2List',
    props: {
        accountant: {
            type: Object,
        },
        currentEnvironment: {
            type: Object,
        },
    },
    components: {
        ProductPageButton,
        CustomTitle,
        SearchInput,
        Dropdown,
        DropdownItem,
        List,
        ListColumn,
        ListRow,
        ListItem,
        VoilaStatePill,
        FriendlyButton,
        Tooltip,
        ContentBox,
        ResendVoilaInviteButton,
        Popup,
        VoilaBoostActivateAllButton,
    },
    data () {
        return {
            ready: false,
            loading: false,
            clients: [],
            paging: {
                count: 0,
            },
            textSearch: '',
            stateCriteria: 'all',
            orderByCriteria: 'sort',
            offset: 0,
            limit: 20,
            resendAllInvitationsPopupShown: false,
            accountantHasBoost: false,
        };
    },
    watch: {
        textSearch () {
            this.fetchList();
        },
        stateCriteria () {
            this.fetchList();
        },
        orderByCriteria () {
            this.fetchList();
        },
        limit () {
            this.fetchList();
        },
        'currentEnvironment.id': function () {
            this.fetchList();
        },
    },
    async mounted () {
        if (this.accountant.hasCodaOnlyContract) {
            // user can't access this page for this environment
            notify.error(t('err-restricted-access'));
            this.$router.push({ name: 'index' });
        } else {
            this.fetchList = _.throttle(this.fetchList, 500);
            await this.fetchList();
            this.accountantHasBoost = await this.isAccountantBoost();
            this.ready = true;
        }
    },
    methods: {
        displayRowLandmark (i) {
            return Number.isInteger(((i + 1) / 20));
        },
        voilaMandateOrderable (c) {
            return c.client.id && c.client.contactEmail &&
                (!c.mandate || [VoilaMandateStates.Cancelled].includes(c.mandate.status));
        },
        voilaMandateCancellable (c) {
            return c.mandate && c.mandate.status && [
                VoilaMandateStates.Invited,
                VoilaMandateStates.InvitationExpired,
                VoilaMandateStates.InValidation,
                VoilaMandateStates.InRegistration,
                VoilaMandateStates.Problem,
                VoilaMandateStates.Active,
            ].includes(c.mandate.status);
        },
        redirectToClient (c) {
            this.$router.push(`/organization/${this.$route.params.organizationId}/environment/${this.$route.params.environmentId}/client/${c.client.id}/voila`);
        },
        async isAccountantBoost () {
            const result = await this.$apollo.query({
                query: gql`query GetAccountantBoost($accountantId: String!) {
                        voilaBoostActivationByAccountant(accountantId:$accountantId) {
                            id
                        }
                    }`,
                variables: {
                    accountantId: this.accountant.id,
                },
            });
            return !!result.data.voilaBoostActivationByAccountant;
        },
        async searchQuery () {
            const results = await this.$apollo.query({
                query: gql`query VoilaSearch($input: VoilaSearchInput) {
                        voilaSearch(input:$input) {
                            paging { count }
                            results {
                                client {
                                    id,
                                    enterpriseName,
                                    clientCode,
                                    contactEmail
                                },
                                mandate {
                                    id,
                                    status,
                                    deliveryEmail
                                }
                            }
                        }
                    }`,
                variables: {
                    input: {
                        fulltext: this.textSearch,
                        state: this.stateCriteria,
                        fiduciaryId: this.currentEnvironment.id,
                        paging: {
                            offset: this.offset,
                            limit: this.limit,
                        },
                    },
                },
            });
            return results.data.voilaSearch;
        },
        async resendAllExpiredVoilaInvitation () {
            try {
                const query = await this.$apollo.query({
                    query: gql`mutation ResendAllExpiredVoilaInvitations($fiduciaryId: String) {
                        resendAllExpiredVoilaInvitations(fiduciaryId: $fiduciaryId) {
                            error {
                                code
                            }
                        }
                    }`,
                    variables: {
                        fiduciaryId: this.currentEnvironment.id,
                    },
                });

                this.closeResendAllInvitationsPopup();

                this.fetchList();

                return query;
            } catch (err) {
                if (err.message === 'err-voila-invitation-no-expired-mandates') {
                    notify.info(this.$t(err.message));
                } else {
                    notify.error(this.$t(err.message));
                }
                this.closeResendAllInvitationsPopup();
                return false;
            }
        },
        async fetchList () {
            this.loading = true;
            this.offset = 0;
            const search = await this.searchQuery();
            this.clients = search.results;
            this.paging = search.paging;
            this.loading = false;
        },
        async loadMore () {
            this.offset += 20;
            const search = await this.searchQuery();
            this.paging = search.paging;
            this.clients = [
                ...this.clients,
                ...search.results,
            ];
        },
        canResendInvitation (m) {
            return m && (m.status === VoilaMandateStates.InvitationExpired || m.status === VoilaMandateStates.Invited);
        },
        openResendAllInvitationsPopup () {
            this.resendAllInvitationsPopupShown = true;
        },
        closeResendAllInvitationsPopup () {
            this.resendAllInvitationsPopupShown = false;
        },
    },
};
</script>

<template>
    <div>
        <div class='mt-6 mb-6 flex items-end'>
            <CustomTitle class='m-0'>
                {{ $t('h-purchase-invoice') }}
            </CustomTitle>
            <div class='ml-auto flex items-center' v-if='ready'>
                <div class='cb-action-label'>
                    {{ $t('lbl-tools') }}
                </div>
                <ProductPageButton />
                <VoilaBoostActivateAllButton class='ml-2' :current-environment='currentEnvironment' v-if='accountantHasBoost' />
                <FriendlyButton
                    class='ml-2'
                    label='btn-reinvite-all'
                    extra-small
                    no-margin
                    square
                    id='voila-resend-expired-invitation-button'
                    :action='openResendAllInvitationsPopup'
                />
            </div>
        </div>

        <content-box>
            <div>
                <div class='flex mb-3'>
                    <SearchInput v-model='textSearch' class='mr-6' id='voila-search-fulltext' />
                    <Dropdown
                        v-model='stateCriteria'
                        :name='$t("th-voila-search-status")'
                        class='z-50'
                        nullable
                        icon='sort'
                        id='voila-search-state-filter'
                        default-value='all'
                        direction='right'
                    >
                        <DropdownItem id='all' :name='$t("lbl-voila-state-all")' default class='whitespace-nowrap'>
                            {{ $t('lbl-voila-state-all') }}
                        </DropdownItem>
                        <DropdownItem id='notInvited' :name='$t("voila-status-notInvited")' class='whitespace-nowrap'>
                            {{ $t('voila-status-notInvited') }}
                        </DropdownItem>
                        <DropdownItem id='invited' :name='$t("voila-status-invited")' class='whitespace-nowrap'>
                            {{ $t('voila-status-invited') }}
                        </DropdownItem>
                        <DropdownItem id='invitationExpired' :name='$t("voila-status-invitationExpired")' class='whitespace-nowrap'>
                            {{ $t('voila-status-invitationExpired') }}
                        </DropdownItem>
                        <DropdownItem id='inValidation' :name='$t("voila-status-inValidation")' class='whitespace-nowrap'>
                            {{ $t('voila-status-inValidation') }}
                        </DropdownItem>
                        <DropdownItem id='inRegistration' :name='$t("voila-status-inRegistration")' class='whitespace-nowrap'>
                            {{ $t('voila-status-inRegistration') }}
                        </DropdownItem>
                        <DropdownItem id='problem' :name='$t("voila-status-problem")' class='whitespace-nowrap'>
                            {{ $t('voila-status-problem') }}
                        </DropdownItem>
                        <DropdownItem id='active' :name='$t("voila-status-active")' class='whitespace-nowrap'>
                            {{ $t('voila-status-active') }}
                        </DropdownItem>
                        <DropdownItem id='cancelled' :name='$t("voila-status-cancelled")' class='whitespace-nowrap'>
                            {{ $t('voila-status-cancelled') }}
                        </DropdownItem>
                    </Dropdown>
                <!--
                <Dropdown v-model='orderByCriteria' name='Order by' icon='sort-amount-asc'>
                    <DropdownItem id='sort'>
                        Client
                    </DropdownItem>
                    <DropdownItem id='2'>
                        Search Filter Item
                    </DropdownItem>
                </Dropdown>
                -->
                </div>
                <List :loading='loading' id='voila-search-list' tooltip-fix>
                    <template #columns>
                        <ListColumn>{{ $t('th-client-code') }}</ListColumn>
                        <ListColumn>{{ $t('th-client-name') }}</ListColumn>
                        <ListColumn>{{ $t('th-voila-search-email') }}</ListColumn>
                        <ListColumn>{{ $t('th-voila-search-status') }}</ListColumn>
                        <ListColumn>{{ $t('th-voila-search-actions') }}</ListColumn>
                    </template>
                    <template #rows>
                        <ListRow
                            v-for='(c, i) in clients'
                            :key='i'
                            :landmark='displayRowLandmark(i)'
                            :background='Number.isInteger(((i+1)/2))'
                        >
                            <ListItem>
                                <router-link
                                    :to='`/organization/${$route.params.organizationId}/environment/${$route.params.environmentId}/client/${c.client.id}`'
                                >
                                    {{ c.client.clientCode }}
                                </router-link>
                            </ListItem>
                            <ListItem>
                                {{ c.client.enterpriseName }}
                            </ListItem>
                            <ListItem>
                                <div v-if='c.mandate && c.mandate.deliveryEmail' class='flex items-center'>
                                    <span class='truncate max-w-xs inline-block'>{{ c.mandate.deliveryEmail }}</span>
                                    <Tooltip>
                                        <template #trigger>
                                            <i class='text-grey-300 fa fa-info-circle ml-1'></i>
                                        </template>
                                        <template #content>
                                            {{ $t('txt-voila-email-origin-mandate') }}
                                        </template>
                                    </Tooltip>
                                </div>
                                <div v-else-if='c.mandate && c.client.contactEmail' class='flex items-center'>
                                    <span class='truncate max-w-xs inline-block'>{{ c.client.contactEmail }}</span>
                                    <tooltip>
                                        <template #trigger>
                                            <i class='text-grey-300 fa fa-info-circle ml-1'></i>
                                        </template>
                                        <template #content>
                                            {{ $t('txt-voila-email-origin-client') }}
                                        </template>
                                    </tooltip>
                                </div>
                                <div v-else>
                                    -
                                </div>
                            </ListItem>
                            <ListItem>
                                <VoilaStatePill :status='c.mandate ? c.mandate.status : null' />
                            </ListItem>
                            <ListItem>
                                <div class='flex'>
                                    <ResendVoilaInviteButton
                                        :mandate-id='c.mandate.id'
                                        :contact-email='c.client.contactEmail'
                                        v-if='canResendInvitation(c.mandate)'
                                        class='mr-3'
                                        @success='c.mandate.status = "invited"'
                                        micro
                                    />
                                    <FriendlyButton
                                        class='voila-order-manage-button'
                                        label='btn-manage'
                                        micro
                                        no-margin
                                        square
                                        :action='() => redirectToClient(c)'
                                    />
                                </div>
                            </ListItem>
                        </ListRow>
                    </template>
                </List>
                <div class='my-12 text-xl ml-3'>
                    {{ clients.length }} {{ $t('txt-voila-search-list-count') }} {{ paging.count }}
                </div>
                <div class='flex justify-center'>
                    <FriendlyButton
                        :label='`btn-loadmore`'
                        :action='loadMore'
                        id='btn-loadmore'
                        extra-small
                        square
                        :disabled='clients.length >= paging.count'
                    />
                </div>
            </div>
        </content-box>
        <Popup :show='resendAllInvitationsPopupShown' :close='closeResendAllInvitationsPopup' small id='voila-resend-expired-invitation-popup'>
            <template #header>
                {{ $t('h-reinvite-clients-all') }}
            </template>

            <p>
                {{ $t('p-reinvite-clients-all') }}
            </p>

            <template #buttons>
                <FriendlyButton
                    label='btn-cancel'
                    class='cancel-voila-resend-invitation'
                    :action='closeResendAllInvitationsPopup'
                    symbol='times'
                    small
                    secondary
                    no-margin
                />
                <FriendlyButton
                    class='voila-order-manage-button ml-auto'
                    label='btn-reinvite-now'
                    small
                    no-margin
                    symbol='check'
                    id='voila-resend-expired-invitation-confirm-button'
                    :action='resendAllExpiredVoilaInvitation'
                />
            </template>
        </Popup>
    </div>
</template>
