<script>
    import download from 'downloadjs';
    import notify from '../notify.js';
    import { authFetch as fetch } from '../api/fetch.js';

    export default {
        name: 'CodaTransmissionDownload',
        props: {
            mandate: {
                type: Object,
                required: true,
            },
        },
        computed: {
            noNeedToDownload () {
                // there is no need to download the TT mandate if
                // there is an other state than draft-active-bank-mandate in the non-archived bank accounts
                // this business logic is also used in CodaMandateButton.vue,
                // so if you change it here, change it there too
                return !this.nonArchivedBankAccountsAllHaveFlowState('draft-active-bank-mandate');
            },
            nonArchivedBankAccounts () {
                return this.mandate.bankAccounts.filter((ba) => ba.state !== 'archived');
            },
        },
        methods: {
            nonArchivedBankAccountsAllHaveFlowState (flowState) {
                return this.nonArchivedBankAccounts.every((ba) => ba.flowState === flowState);
            },
            async download () {
                try {
                    let res = await fetch(
                        `bff/api/downloads/coda-teletransmission-mandate?mandateId=${this.mandate.id}`,
                        {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/pdf',
                            },
                        },
                    );
                    if (res.status !== 200) {
                        throw Error(res.statusText);
                    }
                    download(await res.blob(), 'coda_mandate.pdf');
                } catch {
                    notify.error(this.$t('err-unknown'));
                }
            },
        },
    };
</script>
<template>
    <div class='flex'>
        <button
            v-if='noNeedToDownload'
            disabled
            class='btn btn-alt btn-xs'
        >
            {{ $t('btn-download-transmission') }}
        </button>
        <button v-else class='btn btn-alt btn-xs' @click='download'>
            {{ $t('btn-download-transmission') }}
        </button>
    </div>
</template>
